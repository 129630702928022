import React from "react"

import { Link } from "gatsby"

import { Container, Infos } from "./Hero.styles"

//icons
import ArrowBack from "../../assets/icons/arrow-back.svg"
import Author from "../../assets/icons/author.svg"
import Calendar from "../../assets/icons/calendar.svg"
import Location from "../../assets/icons/location-drop.svg"
export default function NewsHero({
  title,
  date,
  back,
  thumbnail,
  city,
  country,
  backLink,
  added,
  arabic,
}) {
  let monthName = new Date(date.seconds * 1000).toLocaleString("en-US", {
      month: "short",
    }),
    month = new Date(date.seconds * 1000).toLocaleString("en-US", {
      month: "long",
    }),
    day = new Date(date.seconds * 1000).getDate()

  // const NoTimedate = date.split("T")
  // if (date) {
  //   const dateArray = NoTimedate[0].split("-")
  //   month = dateArray[1]
  //   day = dateArray[2]
  //   switch (month) {
  //     case "01":
  //       monthName = "JAN"
  //       break
  //     case "02":
  //       monthName = "FEB"
  //       break
  //     case "03":
  //       monthName = "MAR"
  //       break
  //     case "04":
  //       monthName = "APR"
  //       break
  //     case "05":
  //       monthName = "MAY"
  //       break
  //     case "06":
  //       monthName = "JUN"
  //       break
  //     case "07":
  //       monthName = "JUL"
  //       break
  //     case "08":
  //       monthName = "AUG"
  //       break
  //     case "09":
  //       monthName = "SEP"
  //       break
  //     case "10":
  //       monthName = "OCT"
  //       break
  //     case "11":
  //       monthName = "NOV"
  //       break
  //     case "12":
  //       monthName = "DEC"
  //       break
  //     default:
  //       monthName = ""
  //   }
  // }
  return (
    <div>
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-container">
              <Link to={backLink}>
                <img src={ArrowBack} alt="arrow back" />
                <span>
                  {arabic === false
                    ? "BACK TO CURRENT"
                    : "رجوع إلى الصفحة الحالية"}{" "}
                  {back}
                </span>
              </Link>
              <h1>{title}</h1>
            </div>
            <div className="col-lg-6 image-container">
              <div className="img-content">
                <img src={thumbnail} alt="featured" className="hero-img" />
                <div className="date">
                  <span className="day">{day}</span>
                  <span className="month">{monthName}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="container">
        <Infos>
          {added && (
            <div className="info">
              <img src={Author} alt="author icon" />
              <div className="description">Admin</div>
            </div>
          )}

          <div className="info">
            <img src={Calendar} alt="calendar icon" />
            <div className="description">
              {month} {day}
            </div>
          </div>
          <div className="info">
            <img src={Location} alt="location icon" />
            <div className="description">{/* {city}, {country.label} */}</div>
          </div>
        </Infos>
      </div>
    </div>
  )
}
