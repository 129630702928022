import styled from "styled-components"

export const HeroContainer = styled.div`
  padding: 40px 0;
  background-color: var(--darkBlue);
  color: white;

  p {
    width: 40%;
    font-size: 0.9rem;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
`

export const Container = styled.div`
  background-color: var(--darkBlue);
  padding: 70px 0 140px;

  @media (max-width: 992px) {
    padding: 30px 0;
  }

  .text-container {
    position: relative;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 0.9rem;
      text-decoration: none;
      color: white;
      margin-bottom: 30px;

      img {
        width: 15px;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 3.33rem;
      color: white;
    }

    .location {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;

      span {
        color: white;
      }
    }

    .price {
      h3 {
        font-weight: bold;
        color: white;
      }
      @media (max-width: 992px) {
        margin-bottom: 50px;
      }
    }
  }

  .image-container {
    position: relative;

    img.hero-img {
      height: 300px;
      min-width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
    .img-content {
      position: absolute;
      bottom: calc(-140px - 25%);
      left: 40px;
      min-width: 80%;
      min-height: 200px;

      img {
        border-radius: 10px;
        width: 100%;

        @media (max-width: 576px) {
          border-radius: unset;
        }
      }

      @media (max-width: 992px) {
        position: static;
      }
    }

    .date {
      position: absolute;
      z-index: 1;
      left: 20px;
      bottom: 20px;
      padding: 10px 20px;
      background-color: var(--lightBlue);
      opacity: 89%;
      color: white;
      font-weight: 500;
      text-align: center;

      span.day {
        display: block;
        font-size: 1.3rem;
      }
    }
    @media (max-width: 992px) {
      .date {
        left: 20px;
        bottom: 10px;
      }
    }
  }
`
export const Infos = styled.div`
  padding-top: 15px;
  position: absolute;
  display: flex;

  .info {
    display: flex;
    margin-right: 17px;
    align-items: flex-start;
    .description {
      font-size: 1rem;
      color: #7e7e7e;
    }
    img {
      margin-right: 8px;
    }
  }
  @media (max-width: 992px) {
    position: relative;
  }
  @media (max-width: 500px) {
    display: inline-block;
    .info {
      padding-bottom: 6px;
    }
  }
`

export const ContainerAttendEvent = styled.div`
  background-color: var(--darkBlue);
  padding: 70px 0 140px;

  @media (max-width: 992px) {
    padding: 30px 0;
  }

  .text-container {
    position: relative;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 0.9rem;
      text-decoration: none;
      color: white;
      margin-bottom: 30px;

      img {
        width: 15px;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 3rem;
      color: white;
    }

    .location {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;

      span {
        color: white;
      }
    }

    .price {
      h3 {
        font-weight: bold;
        color: white;
      }
      @media (max-width: 992px) {
        margin-bottom: 50px;
      }
    }
  }

  .image-container {
    position: relative;
    .date {
      position: absolute;
      z-index: 1;
      left: 60px;
      bottom: -177px;
      padding: 10px 20px;
      background-color: var(--lightBlue);
      opacity: 89%;
      color: white;
      font-weight: 500;
      text-align: center;

      span.day {
        display: block;
        font-size: 1.3rem;
      }
    }
    @media (max-width: 992px) {
      .date {
        left: 10px;
        bottom: 10px;
      }
    }
    .hero-img {
      position: absolute;
      bottom: calc(-140px - 25%);
      left: 40px;
      min-width: 80%;
      min-height: 200px;

      img {
        border-radius: 10px;
        width: 100%;

        @media (max-width: 576px) {
          border-radius: unset;
        }
      }

      @media (max-width: 992px) {
        position: static;
      }
    }
  }
  .infos {
    @media (max-width: 992px) {
      position: relative;
    }
    padding-top: 150px;
    position: absolute;
    display: flex;
    .info {
      display: flex;
      margin-right: 17px;
      align-items: flex-start;
      .description {
        font: normal normal normal 18px/24px Roboto;
        letter-spacing: 1.35px;
        color: #7e7e7e;
        opacity: 1;
      }
      img {
        margin-right: 8px;
      }
    }
  }
`

export const DashboardHeroContainer = styled.div`
  background-color: var(--darkBlue);
  color: white;
  padding: 50px 0;
  h1 {
    font-weight: bold;
    font-size: 3.33rem;
    margin-bottom: 20px;
  }
  p {
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
  }
`
