import styled from "styled-components"

export const NewsContainer = styled.div`
  padding: 90px 0;
`

export const UpperText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: bold;
    color: var(--darkText);
    font-size: 2.5rem;
  }

  p {
    width: 50%;
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 19px;
    opacity: 0.8;
  }

  .container p {
    color: var(--lightText);
    font-size: 0.9rem;
    max-width: 400px;
  }
`

export const ArticlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
