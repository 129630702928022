import React, { useState, useContext, useEffect } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import Seo from "../../components/seo"
import EventHero from "../../components/Hero/NewsEventHero"
import EventContent from "../../components/EventNewsBlog"
import { graphql, navigate } from "gatsby"
import { StateContext } from "../../helpers/StateProvider"
import {
  ArticlesContainer,
  NewsContainer,
} from "../../components/LatestNewsBlog/LatestNews.styles"
import { UpperText } from "../../components/ListingsCarousel/ListingsCarousel.styles"
import { HeroContainer } from "../../components/Hero/Hero.styles"
import { StaticImage } from "gatsby-plugin-image"
import {
  ArticleContainer,
  BlogContainer,
  DateContainer,
} from "../../components/GlobalComponents/GlobalComponents.styles"

const NewsDetail = props => {
  const newsId = props.id
  const { authIsReady } = useAuthContext()

  const [newsData, setNewsData] = useState(null)
  const [user, setUser] = useState(null)

  /*useDocument("news", newsId).then(document => {
    if (document.data) {
      setNewsData(document.data)
    }
  })*/
  const { language, setLanguage, title, content, setTitle, setContent } =
    useContext(StateContext)

  useEffect(() => {
    console.log("loaded once and using the local state", props.location.state)
    console.log("language", language)
    if (props.location.state?.blog) {
      setContent(props.location.state.content)
      setTitle(props.location.state.title)
    } else {
      navigate("/news")
    }
  }, [])

  return (
    <>
      {authIsReady && (
        <>
          <HeroContainer>
            <div
              className={
                language == false ? "container text-end" : "container text-end"
              }
            >
              <h1>{title}</h1>
            </div>
          </HeroContainer>
          <BlogContainer>
            <div
              className="blogger"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </BlogContainer>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          isexternal
          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`

export default NewsDetail
