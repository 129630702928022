import styled from "styled-components"

export const Container = styled.div`
  padding: 90px 0;
  .hide {
    display: none !important;
  }
  .green-btn {
    width: auto;
    margin-bottom: 16px;
  }
  a.attend {
    font-size: 0.77rem;
    background-color: var(--lightGreen);
    padding: 1rem 2rem;
    text-align: center;
    color: white;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
  }
`

export const Overview = styled.div`
  h4 {
    font-weight: bold;
    color: black;
    padding-bottom: 20px;
  }
  .bio {
    padding-top: 50px;
  }
  p {
    font-size: 0.95rem;
  }
`

export const MediaContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  .video-container {
    position: relative;
    border-radius: 14px;
    width: 47%;

    @media (max-width: 768px) {
      width: 100%;
    }

    video {
      border-radius: 14px;
      margin: auto 0;
      width: 100%;
    }

    .play {
      height: 50px;
      width: 50px;
      position: absolute;
      left: 50%;
      margin-left: -25px;
      top: 50%;
      margin-top: -25px;
    }
  }

  .img {
    width: 47%;

    @media (max-width: 768px) {
      width: 100%;
    }

    .listing-image {
      width: 100%;
      border-radius: 14px;
    }
  }
`

export const PropertyDetails = styled.div`
  padding: 30px 0;
  h4 {
    color: black;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
`

export const Spec = styled.div`
  .label {
    color: var(--lightGreen);
    margin-bottom: 2px;
    font-size: 0.9rem;
  }
  .value {
    font-weight: bold;
  }
`

export const UnitSpec = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
`

export const Share = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-top: 30px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  .text {
    font-weight: bold;
  }

  .socials {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`
