import styled from "styled-components"
import image from "../../assets/images/hero-img.png"
/** --------------- Event Item Styles --------------- */

export const EventContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;

    article {
      width: 100%;
    }
    .green-btn {
      width: 100%;
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 35%;
  height: 210px;
  border-radius: 8px;
  img {
    width: 100% !important;
    min-width: 189px;
    height: 100% !important;
    max-height: 215px;
  }
  .event-img {
    border-radius: 14px;
    min-height: 210px;
    max-width: 310px;
    object-fit: cover;

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .news-img {
    border-radius: 14px;
    object-fit: cover;
    opacity: 1;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    padding: 0;

    right: 0;
    top: 0;
    width: 100%;
  }

  .date {
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 10px;
    padding: 10px 20px;
    background-color: var(--lightBlue);
    opacity: 89%;
    color: white;
    font-weight: 500;
    text-align: center;

    span.day {
      display: block;
      font-size: 1.3rem;
    }
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    a {
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      color: black;
    }
  }

  .location {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;

    .location {
      img {
        width: 15px;
      }
    }
  }

  p {
    font-size: 1rem;
    color: var(--darkText);
    max-width: 400px;
  }

  a.attend {
    font-size: 0.77rem;
    background-color: var(--lightGreen);
    padding: 1rem 5px;
    width: 100%;
    text-align: center;
    color: white;
    border-radius: 5px;
    text-decoration: none;
  }
`

/** -------------------News Item Styles ----------------- */

export const ArticleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
  direction: rtl;
  h5 {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
    color: var(--darkText);
    max-width: 400px;
  }

  @media (max-width: 768px) {
    .green-btn {
      width: 100%;
    }
  }

  a {
    background-color: var(--lightGreen);
    color: white;
    padding: 10px 0;
    max-width: 42%;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.77rem;
  }
`

export const BlogContainer = styled.div`
  display: block;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 120px;
  direction: rtl;
  padding: 50px;
  h5 {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 1.1rem;
  }

  div {
    font-size: 2rem;
    color: var(--darkText);
    max-width: 400px;
  }

  @media (max-width: 768px) {
    .green-btn {
      width: 100%;
    }
  }

  a {
    background-color: var(--lightGreen);
    color: white;
    padding: 10px 0;
    max-width: 42%;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.77rem;
  }
`

export const DateContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;

  .author,
  .date {
    display: flex;
    gap: 5px;
    align-items: center;

    p {
      margin: 0;
      color: var(--lightText);
    }
  }
`

/** ------------------ Explore More ------------------- */

export const ExploreMoreStyles = styled.div`
  a {
    text-decoration: none;
    color: var(--lightGreen);
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;

    span {
      line-height: 1;
    }

    &:hover {
      color: var(--lightGreen);
      span.arrow {
        transform: translateX(10px);
        transition: 0.2s ease;
      }
    }
  }
`

/** -----------Inputs ---------------- */
export const InputContainer = styled.label`
  height: 50px;
  position: relative;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: ${({ isFocus }) => (isFocus ? "5px 10px" : "20px 10px 5px")};
  margin-bottom: 20px;

  span {
    font-size: 0.66rem;
    color: var(--lightGreen);
    font-weight: bold;
    position: absolute;
    top: ${({ isFocus }) => (isFocus ? "-20px" : "-1px")};
    z-index: 1;
    transition: 0.2s;
  }

  input {
    width: 100%;
    border: none;
    height: ${({ isFocus }) => (isFocus ? "38px" : "unset")};

    &:focus {
      outline: none;
    }
  }
`

export const TextareaContainer = styled.label`
  position: relative;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: ${({ isFocus }) => (isFocus ? "5px 10px" : "20px 10px 5px")};
  margin-bottom: 20px;

  span {
    font-size: 0.66rem;
    color: var(--lightGreen);
    font-weight: bold;
    position: absolute;
    top: ${({ isFocus }) => (isFocus ? "-20px" : "-1px")};
    z-index: 1;
    transition: 0.2s;
  }
  textarea {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }
`

export const SelectContainer = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 20px;

  .content {
    width: 100%;
    position: relative;

    span {
      font-size: 0.66rem;
      color: var(--lightGreen);
      font-weight: bold;
      position: absolute;
      z-index: 1;
      transition: 0.2s;
      top: ${({ isFocus }) => (isFocus ? "-25px" : "-5px")};
    }
  }
`
