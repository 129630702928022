import styled from "styled-components"

export const SwiperContainer = styled.div`
  background-color: #f4f4f4;
  padding: 40px 0;

  .swiper-pagination {
    position: static;
    margin-top: 30px;
  }

  .swiper-navigation {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 10px;

    .nav-btn {
      padding: 15px;
      background-color: #e6e6e8;
      border-radius: 50%;
      cursor: pointer;
    }
  }
`

export const UpperText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
    color: var(--darkText);
    font-size: 2.5rem;
  }

  p {
    color: var(--lightText);
  }

  .text-up {
    width: 50%;
  }
`
